<template>
    <div id="app">
        <a-modal v-model="show" title="角色信息" :width="700" @ok="doSubmit" @cancel="_close" centered>
            <a-form-model ref="form" :model="formData" :rules="formRule">
                <a-row :gutter="8">
                    <a-col span="12">
                        <a-form-model-item label="角色名称" label-position="top" prop="name">
                            <a-input v-model="formData.name" placeholder="请输入角色名称"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="6" v-if="apiUtil.getOrganId() == 0">
                        <a-form-model-item label="类型/单位" label-position="top" prop="organId">
                            <a-select v-model="formData.organId" style="width:100%">
                                <a-select-option :value="0">系统角色</a-select-option>
                                <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{
                                    organ.name }}</a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col span="6">
                        <a-form-model-item label="是否可用" label-position="top" prop="status">
                            <a-radio-group button-style="solid" v-model="formData.status" :false-value="0" :true-value="1">
                                <a-radio-button :value="1">是</a-radio-button>
                                <a-radio-button :value="0">否</a-radio-button>
                            </a-radio-group>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="8">
                    <a-col span="24">
                        <a-form-model-item label="角色描述" label-position="top" prop="description">
                            <a-input type="textarea" v-model="formData.description" placeholder="请输入角色描述"></a-input>
                        </a-form-model-item>
                    </a-col>

                </a-row>

            </a-form-model>
            <a-spin size="large" fix v-if="loading" />
        </a-modal>
    </div>
</template>

<script>
import request, { apiUrl, editorUpload } from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
export default {
    data() {
        return {
            show: false,
            title: '添加角色',
            loading: false,
            organList: apiUtil.getOrganList(),
            styles: {
                height: 'calc(100% - 55px)',
                overflow: 'auto',
                paddingBottom: '53px',
                position: 'static'
            },
            formData: {
                name: '',
                description: '',
                organId: apiUtil.getOrganId(),
                status: 1
            },
            formRule: {
                name: [{ required: true, message: '请输入角色名称' }],
            }
        }
    },
    mounted() {

    },
    methods: {
        editorUpload,
        _show(form) {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                if (!form) {
                    this.formData.roleId = '';
                    this.title = "添加"
                } else {
                    this.formData = JSON.parse(JSON.stringify(form));
                    this.title = "修改";
                }
            })

        },
        _close() {
            this.show = false;
            this.$emit('close')
        },
        doSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    utils.showSpin();
                    request.post('/platform/role/save', this.formData).then(ret => {
                        // console.log(ret)
                        this.show = false;
                        if (ret.code == 200) {
                            utils.success('操作成功！').then(() => {
                                this.$refs.form.resetFields();
                                this.show = false;
                                this.$emit('success');
                            })
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
}
</style>
